<template>
  <div>
    <cabecalho />
    <dobra />
    <separador />
    <servicos />
    <!-- <faq /> -->
    <separador />
    <orcamento />
    <separador />
    <whats-app />
    <biografia />
    <rodape />
  </div>
</template>

<script>
// Componentes
import Cabecalho from '@/components/Cabecalho'
import Biografia from '@/components/Biografia'
import Dobra from '@/components/Dobra'
import Servicos from '@/components/Servicos'
import Rodape from '@/components/Rodape'
import Orcamento from '@/components/Orcamento.vue'
import WhatsApp from '@/components/WhatsApp.vue'
// import Faq from '@/components/Faq.vue'
import Separador from '@/components/Separador.vue'

export default {
  title: 'Carlos Roberto - Designer Gráfico & Desenvolvedor',
  components: {
    Cabecalho,
    Dobra,
    Servicos,
    Biografia,
    Orcamento,
    WhatsApp,
    Rodape,
    Separador,
    // Faq,
  },
}
</script>
