<template>
  <router-view />
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import '@fortawesome/fontawesome-free/css/all.css'

// Autorais:
import '@/assets/css/root.css'
import '@/assets/css/html.css'
import '@/assets/css/fonts.css'
import '@/assets/css/style.css'
import '@/assets/css/background-image.css'
import '@/assets/css/container.css'
import '@/assets/css/espacamento.css'
import '@/assets/css/util.css'

export default {}
</script>
