import { createWebHistory, createRouter } from 'vue-router'
import Principal from '@/views/Principal.vue'
import Erro404 from '@/views/Erro404.vue'

const routes = [
  {
    path: '/',
    component: Principal,
  },
  {
    path: '/:pathMatch(.*)*',
    component: Erro404,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
