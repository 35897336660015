<template>
  <div class="container-fluid espacamento--padrao background--secundario">
    <a name="orcamento"></a>
    <div class="container">
      <div class="row justify-content-lg-center">
        <div class="col-lg-10 formulario-orcamento">
          <div class="row">
            <div class="col-sm-12">
              <div class="container__cabecalho">
                <h2 class="container__titulo">Pronto para Começar?</h2>
                <h3 class="container__sub">
                  Então
                  <span class="sublinhar"> clica no botão abaixo</span> para
                  começar a converter!
                </h3>
              </div>
            </div>
          </div>

          <div class="row justify-content-lg-center">
            <div class="col-lg-10">
              <div class="whatsapp__botoes">
                <a
                  href="https://wa.me/message/A7OSIL4AT7JOG1"
                  target="_blank"
                  class="btn btn-primary whatsapp__botao"
                  @click="whatsApp()"
                >
                  <i class="fab fa-whatsapp"></i>
                  Chamar no WhatsApp!</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.whatsapp__botoes {
  margin-top: -30px;
  margin-bottom: 50px;
  text-align: center;
}

.whatsapp__botoes .fab {
  font-size: 1.3em;
  padding-right: 5px;
  display: inline-block;
  vertical-align: middle;
}

.whatsapp__botao {
  margin: 10px;
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--cor-tema-secundaria);
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
}

.whatsapp__botao:hover {
  color: var(--cor-tema-secundaria);
}

@media (max-width: 576px) {
  .whatsapp__botao {
    font-size: 1.5rem;
    margin: 0;
  }
  .whatsapp__botao {
    width: 100%;
  }
}
</style>

<script>
export default {
  methods: {
    whatsApp() {
      this.$analytics.fbq.event('Contact')
    },
  },
}
</script>
