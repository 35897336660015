<template>
  <div
    class="container-fluid background--principal espacamento--padrao background--svg-01"
  >
    <div class="container">
      <div class="row justify-content-lg-center">
        <div class="col-lg-10 dobra">
          <h2 class="dobra__titulo">
           <span class="sublinhar">Erro 404!</span> Página não encontrada...
          </h2>

          <h3 class="dobra__sub">
            A página que está tentando acessar <span class="sublinhar">não está disponível.</span>
          </h3>
          <div class="dorda__botoes">
            <a href="/" class="btn btn-primary dobra__botao"
              >Me leve para algum lugar!</a
            > 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
