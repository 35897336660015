<template>
  <div class="footer__text">
    <h3 class="footer__title">Newsletter</h3>
    <p>
      Receba conteúdo exclusivo em seu e-mail, sem se preocupar com spam ou
      e-mails chatos.
    </p>
    <form
      v-bind:action="urlNewsletter"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      class="validate footer__form"
      target="_blank"
    >
    <a name="newsletter"></a>
      <div class="input-group footer__newsletter">
        <input
          type="email"
          name="EMAIL"
          class="form-control input--tema newsletter__input"
          placeholder="Digite seu e-mail aqui!"
          aria-label="Digite seu e-mail aqui!"
          required
        />
        <button class="btn btn-primary" type="submit">
          Quero conteúdo!
        </button>
      </div>
    </form>
  </div>
</template>

<style>
@media (max-width: 576px) {
  .footer__newsletter {
    display: block;
  }

  .newsletter__input {
    width: 100% !important;
    display: block;
    margin-bottom: 20px;
  }
}
</style>

<script>
export default {
  computed: {
    urlNewsletter(){
      return process.env.VUE_APP_URL_NEWSLETTER
    }
  }
}
</script>