<template>
  <div class="container-fluid espacamento--padrao background--secundario">
    <a name="orcamento"></a>
    <div class="container">
      <div class="row justify-content-lg-center">
        <div class="col-lg-10 formulario-orcamento">
          <div class="row">
            <div class="col-sm-12">
              <div class="container__cabecalho">
                <h2 class="container__titulo">Orçamento</h2>
                <h3 class="container__sub">
                  Quero <span class="sublinhar">receber uma proposta!</span>
                </h3>
              </div>
            </div>
          </div>

          <div class="row justify-content-lg-center">
            <div class="col-lg-10">
              <formulario-orcamento :formulario="formulario" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormularioOrcamento from './formularios/Orcamento.vue'
import FormularioInputs from '../../data/formulario.json'
import Info from '../../data/site.json'

export default {
  components: { FormularioOrcamento },
  data() {
    return {
      formulario: {
        ...Info.formulario,
        inputsFormulario: {
          ...FormularioInputs,
        },
      },
    }
  },
}
</script>
