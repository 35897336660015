<template>
  <div
    class="container-fluid background--principal espacamento--padrao background--svg-01"
  >
    <div class="container">
      <div class="row justify-content-lg-center">
        <div class="col-lg-10 dobra">
          <h2 class="dobra__titulo">
            O suporte que <span class="sublinhar">você precisa</span> para
            começar a <span class="sublinhar">converter!</span>
          </h2>

          <h3 class="dobra__sub">
            Comece <span class="sublinhar">com o que tem</span> e evolua no seu
            próprio tempo!
          </h3>
          <div class="dorda__botoes">
            <a
              href="#jornada-do-sucesso"
              class="btn btn-primary dobra__botao"
              @click="verMais()"
              >Quero saber como!</a
            >
            <a href="#orcamento" class="btn btn-secondary dobra__botao"
              >Solicitar orçamento...</a
            >
          </div>

          <a href="#jornada-do-sucesso" class="dobra__arrow-link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="90px"
              viewBox="0 0 24 24"
              width="90px"
              fill="#15181d"
              class="dobra__arrow"
            >
              <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
              <path
                d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    verMais() {
      this.$analytics.fbq.event('ViewContent')
    },
  },
}
</script>

<style>
.dobra {
  padding-top: var(--espacamento-grande);
  padding-bottom: var(--espacamento-grande);
  text-align: center;
}

.dobra__titulo {
  font-weight: 700;
  line-height: 1.5em;
  font-size: 4rem;
}

.dobra__sub {
  font-weight: normal;
  padding-top: 30px;
}

.dorda__botoes {
  margin-top: 60px;
}

.dobra__botao {
  margin: 10px;
  font-size: 1.3rem;
}

.dobra__arrow {
  margin-top: 45px;
}

@media (max-width: 992px) {
  .dobra__arrow-link,
  .dobra__arrow {
    display: none;
  }
  .dobra__titulo {
    font-size: 3rem;
  }
}

@media (max-width: 576px) {
  .dobra {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .dobra__titulo {
    font-size: 2.2rem;
  }
}
</style>
