<template>
  <div class="container-fluid background--secundario">
    <div class="container">
      <div class="row">
        <div class="col-12 cabecalho">
          <h1 class="cabecalho__titulo">
            <span class="destacar">Carlos Roberto</span> - caarlos.com
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.cabecalho {
  text-align: center;
  padding-top: 30px;
  /* padding-bottom: 15px; */
}

.cabecalho__titulo {
  font-size: 1.25rem;
  font-weight: normal;
}

.cabecalho__sub h3 {
  font-weight: 400;
}

.cabecalho__sub span {
  font-weight: bold;
  border-bottom: 2px solid var(--cor-destaque);
}
</style>
