<template>
  <div>
    <cabecalho />
    <erro />
  </div>
</template>
<script>
import Cabecalho from '@/components/Cabecalho'
import Erro from '@/components/Erro'
export default {
  title: 'Error 404 - A página sumiu!',
  components: {
    Erro,
    Cabecalho,
  },
}
</script>
