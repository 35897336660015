<template>
  <div class="container-fluid espacamento--padrao background--secundario background--svg-02-invertido">
    <a name="servicos"></a>
    <div class="container">
      <div class="row justify-content-lg-center">
        <div class="col-lg-10 servicos">
          <div class="row">
            <div class="col-sm-12">
              <div class="container__cabecalho">
                <h2 class="container__titulo">Serviços</h2>
                <h3 class="container__sub">
                  O que vai <span class="sublinhar">te fazer converter.</span>
                </h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 container__componentes">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-stickies"
                viewBox="0 0 16 16"
              >
                <path
                  d="M1.5 0A1.5 1.5 0 0 0 0 1.5V13a1 1 0 0 0 1 1V1.5a.5.5 0 0 1 .5-.5H14a1 1 0 0 0-1-1H1.5z"
                />
                <path
                  d="M3.5 2A1.5 1.5 0 0 0 2 3.5v11A1.5 1.5 0 0 0 3.5 16h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 16 9.586V3.5A1.5 1.5 0 0 0 14.5 2h-11zM3 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V9h-4.5A1.5 1.5 0 0 0 9 10.5V15H3.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V10.5a.5.5 0 0 1 .5-.5h4.293L10 14.793z"
                />
              </svg>
              <h3 class="componente__titulo">
                Design Profissional
              </h3>
              <div class="componente__texto">
                <p>
                  Atraia a atenção dos seus clientes com
                  <span class="sublinhar">mídias profissionais</span> em suas
                  redes sociais e propagandas.
                </p>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 container__componentes">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-window-sidebar"
                viewBox="0 0 16 16"
              >
                <path
                  d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                />
                <path
                  d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v2H1V3a1 1 0 0 1 1-1h12zM1 13V6h4v8H2a1 1 0 0 1-1-1zm5 1V6h9v7a1 1 0 0 1-1 1H6z"
                />
              </svg>

              <h3 class="componente__titulo">
                Sites, Landing Pages e Páginas de Captura
              </h3>
              <div class="componente__texto">
                <p>
                  Aumente a <span class="sublinhar">converção</span> das suas
                  campanhas com páginas de caputra e
                  <span class="sublinhar">rankeie no Google.</span>
                </p>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-4 container__componentes">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-megaphone"
                viewBox="0 0 16 16"
              >
                <path
                  d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z"
                />
              </svg>
              <h3 class="componente__titulo">Tráfego Pago</h3>
              <div class="componente__texto">
                <p>
                  Converta, alcance e conquiste novos clientes com
                  <span class="sublinhar">campanhas profissionais</span> nas
                  redes sociais.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bi {
  margin-top: 30px;
  height: 80px;
  color: var(--cor-destaque);
  width: auto;
  margin-bottom: calc(0.75 * var(--espacamento-pequeno));
}
</style>
