<template>
  <div
    class="container-fluid espacamento--topo background--principal background--svg-02"
  >
    <div class="container sobre">
      <div class="row justify-content-lg-center">
        <div class="col-lg-8 col-md-12">
          <div class="row justify-content-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-8">
              <img
                class="img-gravatar"
                src="https://gravatar.com/avatar/9a82ba52769cbc22951a7cf516124307?s=250"
                alt="Carlos Roberto"
              />
            </div>

            <div class="col-lg-9 col-md-8 col-sm-12 ">
              <h2 class="sobre__tilte">Quem sou?</h2>
              <a name="sobre"></a>
              <div class="sobre__container"></div>
              <div class="sobre__text">
                <p>
                  Meu nome é Carlos,
                  <span class="sublinhar"
                    >sou Designer Gráfico e Desenvolvedor.</span
                  >
                  Trabalho na área de Design desde 2017, com experiências de
                  trabalho com agências e clientes próprios. Autodidata, atuo
                  nas áreas de
                  <span class="sublinhar"
                    >design, desenvolvimento e tráfego pago.</span
                  >
                </p>
                <div class="icones-sociais">
                  <a href="https://github.com/caarlos1"
                    ><i class="fab fa-github"></i
                  ></a>
                  <a href="https://www.behance.net/carlos-roberto"
                    ><i class="fab fa-behance"></i
                  ></a>
                  <a href="https://www.linkedin.com/in/carlos-roberto/"
                    ><i class="fab fa-linkedin"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.img-gravatar {
  width: 100%;
  border-radius: 50%;
  border: 6px solid var(--cor-texto-principal);
  box-shadow: var(--sombra-padrao);
  margin-bottom: 50px;
}

.icones-sociais i {
  font-size: 1.8em;
  margin: 0 15px 0 0;
}

.icones-sociais a {
  transition: var(--transicao-padrao);
  color: var(--cor-texto-principal);
}

.icones-sociais a:hover {
  color: var(--cor-texto-principal);
}

.sobre {
  padding-top: calc(1.5 * var(--espacamento-pequeno));
}

.sobre h2,
.sobre__title {
  font-size: 2.5rem;
  font-weight: bold;
  padding-bottom: 10px;
}
.sobre__text {
  font-size: var(--texto-font-size);
  line-height: var(--texto-line-height);
}
</style>
