<template>
  <div class="footer-site">
    <div class="container-fluid espacamento--topo background--principal">
      <div class="container footer">
        <div class="row justify-content-lg-center">
          <div class="col-lg-10">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-12">
                <h3 class="footer__title ">Informações</h3>
                <div class="footer__text">
                  <p>
                    Site criado com <b>Vue.js</b> e hospedado pelo
                    <b>Cloudflare Pages</b>. Uma landing page
                    <span class="sublinhar">rápida, simples e eficiente.</span>
                  </p>
                  <p>
                    <b>Comercial: </b>
                    <a href="mailto:contato@caarlos.com">contato@caarlos.com</a>
                    <br />
                    <b>Telefone: </b>
                    <a
                      href="https://wa.me/message/A7OSIL4AT7JOG1"
                      target="_blank"
                      rel="noopener noreferrer"
                      >WhatsApp</a
                    >
                  </p>
                </div>
              </div>

              <div class="col-lg-6">
                <Newsletter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid copyright">
      <div class="container">
        <div class="row justify-content-lg-center">
          <div class="col-lg-10 copyright__text" v-html="info.pagina.rodape"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Newsletter from './formularios/Newsletter.vue'
import Info from '../../data/site.json'
export default {
  components: { Newsletter },
  data() {
    return {
      info: Info,
    }
  },
}
</script>

<style>
.footer {
  padding-bottom: 50px;
}
.footer {
  border-top: 1px solid var(--cor-tema-secundaria);
  padding-top: 70px;
  padding-bottom: 50px;
}

.footer__title {
  font-size: 1.8rem;
  font-weight: bold;
  padding-bottom: 20px;
}

.footer__text {
  font-size: var(--texto-font-size);
  padding-bottom: calc(var(--espacamento-pequeno) / 2);
}

.footer__text b {
  font-weight: 600;
}

.footer__text a,
.copyright__text a {
  color: var(--cor-texto-principal);
  text-decoration: none;
}

.footer__newsletter {
  padding-top: 8px;
}

.copyright {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #15181d;
  color: var(--cor-texto-principal);
}
</style>
